import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { flatten } from "lodash";

import { Options } from "@vendor-app/app/_sharedComponents/TableSearch/libs";

export function getVariantOptions(purchasables: IPurchasable[]): Options {
  return flatten(
    purchasables.map(purch => {
      if (!purch.vnts?.length) {
        return [];
      }
      return purch.vnts.map(vnt => ({ ...vnt, purchasableName: purch.purchasableName }));
    })
  ).map(vnt => ({
    label: `${vnt.purchasableName ?? ""} ${
      vnt.purchasableVariantName === "Default Title" ? "" : vnt.purchasableVariantName
    }`,
    value: vnt.shopifyId!,
  }));
}
